(async function () {
  const showPartnershipBanner = await require('advantage-network');

  await showPartnershipBanner({
    headerClassname: 'main-header',
    containerClassname: 'content',
    sidenavClassname: 'sidenav',
    sidenavHeaderClassname: 'sidenav__header',
    device: 'mobile'
  });
})();
